import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styling/login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import logo from '../media/logo.png';

import { TextInput } from '../components/TextInput';
import { Submit } from '../components/Submit';
import axiosInstance from '../REST/axios';
import { useEffect } from 'react';

export const ResetPassword = () => {
  const [message, setMessage] = useState('');

  const [localState, setLocalState] = useState({
    email: '',
    loading: false,
  });

  const onChangeInput = (event) => {
    setLocalState({ ...localState, email: event.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLocalState({ ...localState, loading: true });

    try {
      const response = await axiosInstance.post('/password-recovery', {
        email: localState.email,
      });
      const data = response.data;

      setMessage(
        'Om du har ett konto så kommer du strax få ett e-postmeddelande för återställning av lösenord.'
      );
    } catch (e) {
      setMessage('Something went wrong resetting the password.');
    } finally {
      setLocalState({ ...localState, loading: false });
    }
  };

  return (
    <div className='section login'>
      <img className='img__logo' src={logo} />
      <Link to='/' className='go-back'>
        <FontAwesomeIcon icon={faChevronLeft} />
        Gå tillbaka till inloggning
      </Link>
      <h2>
        Välkommen till <span class='lowecase'>I</span>Turn och OASen
      </h2>
      <h1 className='heading' style={{ textTransform: 'none' }}>
        Har du glömt ditt lösenord?
      </h1>
      <p>Vänligen skriv in din email adress så hjälper vi dig!</p>

      <div className='landing-page'>
        <form className='landing-page__sign-up' onSubmit={onSubmit}>
          <div className='form-wrapper'>
            <TextInput
              type='email'
              for='email'
              label='E-mail'
              required='required'
              onChange={onChangeInput}
            />
          </div>

          <p style={{ color: 'green' }}>{message}</p>
          <Submit
            loading={localState.loading}
            value='Skicka återställningslänk'
          />
        </form>
      </div>
    </div>
  );
};
